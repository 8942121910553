<template>
  <form v-if="inputData && form" class="space-y-6 w-full divide-y divide-gray-200 dark:divide-gray-700">

    <div class="grid grid-cols-1 md:grid-cols-2 gap-6">
      <div class="col-span-1">
        <Select v-model="form.bank" :items="inputData.banks" :errors="errors.bank" placeholder="Banka" />
      </div>

      <div class="col-span-1">
        <Input name="iban" placeholder="Konta nr." v-model="form.iban" :errors="errors.iban" />
      </div>
    </div>

    <div class="md:flex-shrink-0 flex items-center flex-wrap ml-auto gap-3 justify-end pt-4">
      <template v-if="!loading">
        <button type="button" class="flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-gradient-to-r from-button2-from to-button2-to hover:from-button2-to hover:to-button2-from focus:outline-none focus:border-none transition duration-500 ease-in-out" @click.prevent="submit">
          Saglabāt
        </button>
      </template>
      <template v-else>
        <Loading />
      </template>

      <button @click.prevent="hideEditBankDetailsForm" type="button" class="inline-flex items-center px-4 py-2 border border-gray-300 dark:border-gray-500 shadow-sm text-sm font-medium rounded-md text-gray-700 dark:text-gray-300 bg-white dark:bg-gray-750 hover:bg-gray-50 dark:hover:bg-gray-770 focus:outline-none focus:ring-0">
        Atcelt
      </button>

    </div>
  </form>
</template>

<script>
import { mapGetters } from "vuex";
import Select from "@/components/Components/Select";
import Input from "@/components/Components/Input";
import Validation from "@/modules/Validation";
import Loading from "@/components/Components/Loading";

export default {
  name: "EditBankDetails",
  components: {
    Select,
    Input,
    Loading
  },
  props: {
    customer: {
      type: Object,
      required: true,
    }
  },
  data: () => ({
    form: null,
    formValidation: {
      bank: {
        rules: ['required']
      },
      iban: {
        rules: ['required']
      }
    }
  }),
  mounted() {
    this.$store.dispatch("getCustomerInputData");
    this.form = {
      bank: this.customer.bank,
      iban: this.customer.iban,
    }
  },
  computed: {
    ...mapGetters({
      inputData: "customerInputData",
      loading: "loading",
      errors: 'errors',
    }),
  },
  methods: {
    hideEditBankDetailsForm() {
      this.$store.dispatch("getCustomer", this.customer.id);
      this.$store.dispatch("removeAllFormsForDisplay");
    },
    submit() {
      this.$Progress.start();
      if (Validation(this.formValidation, this.form)) {
        this.$store.dispatch("updateCustomer", {
          id: this.customer.id,
          bank_id: this.form.bank ? this.form.bank.id : "",
          iban: this.form.iban,
        });
      } else {
        this.$Progress.fail();
      }
    },
  },
}
</script>

<style>

</style>