<template>
  <item-card>
    <template v-slot:title>
      <h3 class="text-lg leading-6 font-medium text-gray-900 dark:text-gray-300">
        Banka
      </h3>
    </template>
    <template v-slot:buttons>
      <template v-if="!displayForm('EditBankDetailsForm') && customer.bank">
        <Button icon="pencil" @click="showForm('EditBankDetailsForm')" />
      </template>
    </template>
    <template v-slot:content>

      <template v-if="customer.bank">
        <template v-if="!displayForm('EditBankDetailsForm')">
          <ShowBankDetails :customer="customer" />
        </template>

        <template v-else>
          <EditBankDetails :customer="customer" />
        </template>
      </template>

      <template v-else>
        <template v-if="!displayForm('EditBankDetailsForm')">
          <div class="w-full">
            <p class="text-center text-lg text-gray-400 mb-3">Klientam nav pievienota banka</p>
            <button @click="showForm('EditBankDetailsForm')" type="button" class="block mx-auto items-center px-3 py-2 border border-gray-300 dark:border-gray-500 shadow-sm text-sm leading-4 font-medium rounded-md text-gray-700 dark:text-gray-300 bg-white dark:bg-gray-750 hover:bg-gray-50 dark:hover:bg-gray-770 dark:hover:bg-gray-770 focus:outline-none focus:ring-0 hover:bg-gray-100 transition duration-500 ease-in-out">
              Pievienot
            </button>
          </div>
        </template>

        <template v-else>
          <EditBankDetails :customer="customer" />
        </template>
      </template>

    </template>
  </item-card>
</template>

<script>
import { mapGetters } from "vuex";
import ItemCard from "@/components/Components/ItemCard"
import ShowBankDetails from "@/components/Customer/CustomerDetails/BankDetails/ShowBankDetails"
import EditBankDetails from "@/components/Customer/CustomerDetails/BankDetails/EditBankDetails"

export default {
  name: "CustomerBankDetails",
  components: {
    ItemCard,
    ShowBankDetails,
    EditBankDetails,
  },
  props: {
    customer: {
      type: Object,
      required: true,
    }
  },
  computed: {
    ...mapGetters({
      formsForDisplay: 'formsForDisplay'
    }),
  },
  methods: {
    showForm(formName){
      this.$store.dispatch('addFormForDisplay', formName)
    },
    displayForm(formName){
      return this.formsForDisplay.includes(formName)
    },
  },
}
</script>

<style>

</style>