<template>
  <item-text title="Nosaukums" :text="`${customer.bank ? customer.bank.name :  'Nav norādīts'}`" />
  <item-text title="SWIFT" :text="`${customer.bank ? customer.bank.swift :  'Nav norādīts'}`" />
  <item-text title="Konta nr." :text="`${customer.iban ? customer.iban :  'Nav norādīts'}`" />
</template>

<script>
import ItemText from "@/components/Components/ItemText"

export default {
  name: "ShowBankDetails",
  components: {
    ItemText
  },
  props: {
    customer: {
      type: Object,
      required: true,
    }
  }
}
</script>

<style>

</style>